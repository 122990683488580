<template>
  <div class="scenicspot">
    <Header class="hide" />
    <Headers class="dispy" />
    <BannerImage rulename="scenicspot_banner" />
    <div class="w">
      <Breadcrumb :crumblist="crumbdata" class="hide" />
      <div class="strip">
        <div class="car">旅游景点</div>
      </div>
      <div class="stop-box">
        <el-row :gutter="22">
          <ul class="stop-list">
            <li class="stop-item" v-for="item in result" :key="item.id">
              <el-col :md="6">
                <router-link
                  :to="{
                    path: '/scenicDetail',
                    query: { id: item.id },
                  }"
                >
                  <div class="whole">
                    <div class="is">
                      <img
                        v-if="item.theLocal"
                        :src="`/jeecg-boot/${item.theLocal}`"
                        class="imgleft"
                      />
                      <img
                        v-else
                        src="@/assets/image/main/zanwutp.png"
                        alt=""
                        class="imgleft"
                      />
                    </div>
                    <div class="whole-info">
                      <div class="title">
                        <span class="text lf">{{ item.theTitle }}</span>
                        <span class="xia lr"> >> </span>
                      </div>
                      <div class="jq clear">
                        <span class="jq-text">
                          <span v-if="item.theRank == 1">A</span>
                          <span v-if="item.theRank == 2">AA</span>
                          <span v-if="item.theRank == 3">AAA</span>
                          <span v-if="item.theRank == 4">AAAA</span>
                          <span v-if="item.theRank == 5">AAAAA</span>景区
                        </span>
                      </div>
                      <div class="xq">
                        <p>咨询电话: {{ item.theTel }}</p>
                        <p>门　　票: {{ item.theBill }}</p>
                        <p>景区地址: {{ item.theAdd }}</p>
                      </div>
                    </div>
                  </div>
                </router-link>
              </el-col>
            </li>
          </ul>
        </el-row>
        <Pagi
          :total="pagi.total"
          :current="pagi.current"
          :sizes="pagi.size"
          @pagicurrent="togglePagi"
        />
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Breadcrumb from "@/components/main/Breadcrumb";
import BannerImage from "@/components/main/BannerImage";
import Header from "@/components/main/Header";
import Headers from "@/components/wap/Headers";
import Footer from "@/components/main/Footer";
import Pagi from "@/components/Pagi";
import { getAction } from "@/api/manage";
export default {
  components: {
    Headers,
    Header,
    Footer,
    Pagi,
    Breadcrumb,
    BannerImage,
  },
  created() {
    this.loadData(1);
  },
  data() {
    return {
      crumbdata: [
        { name: "景点与路线", url: "" },
        { name: "旅游景点", url: "/scenicspot" },
      ],
      result: [],
      pagi: {
        total: 0,
        current: 1,
        size: 10,
      },
    };
  },
  methods: {
    loadData(value) {
      getAction("/scenery/tourScenery/list", {
        pageNo: value,
      }).then((res) => {
        if (res.success) {
          this.result = res.result.records;
          this.pagi.total = res.result.total;
          this.pagi.current = res.result.current;
          this.pagi.size = res.result.size;
        }
      });
    },
    togglePagi(value) {
      this.loadData(value);
    },
  },
};
</script>
<style lang="scss" scoped>
.scenicspot {
  .stop-box {
    padding: 20px 18px 50px;
    min-height: 500px;
    .stop-list {
      .stop-item {
        padding: 0px 20px 0 0;
        .whole {
          width: 100%;
          height: 362px;
          margin-bottom: 22px;
          background-color: #ffffff;
          border: solid 1px #dbdbdb;
          transition: all 0.3s;
          &:hover {
            transition-delay: 0.2s;
            box-shadow: 0px 2px 5px 0px rgba(42, 74, 128, 0.13);
          }
          .is {
            height: 194px;
            overflow: hidden;
          }

          img {
            width: 95%;
            height: 194px;
            margin: 6px auto 0;
            display: block;
            transition: all 0.6s;
            &:hover {
              transform: scale(1.1);
            }
          }
          .whole-info {
            padding: 17px 17px 23px;
            .title {
              font-size: 18px;
              color: #333333;
              .text {
                width: 194px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .xia {
                font-size: 16px;
                position: relative;
                top: 4px;
              }
            }
            .jq {
              .jq-text {
                width: 67px;
                height: 14px;
                font-size: 14px;
                line-height: 29px;
                color: #6EB400;
                letter-spacing: 2px;
              }
            }
            .xq {
              p {
                font-size: 14px;
                color: #666666;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}
</style>
